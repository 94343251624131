import * as Sentry from "@sentry/nuxt";
import { useCheckout } from "~/composables/useCheckout";
import { CheckoutSuccessStatus } from "~/types/checkoutSuccess";
import { useAwinTrackingClient } from "~/composables/api/tracking/useAwinTrackingClient";
import { useIterableTrackingClient } from "~/composables/api/tracking/useIterableTrackingClient";
import { useBiTrackingClient } from "~/composables";
import { useMetaTrackingClient } from "~/composables/api/tracking/useMetaTrackingClient";
import { type SetupIntentMetadata, StripePaymentMethod } from "~/types/stripe";
import type { UserType } from "~/stores/useUserStore";
import { createQueryParameters } from "~/utils/queryParams";

export default defineNuxtRouteMiddleware(async (to) => {
  if (import.meta.client) return;

  const { $pinia, $i18n } = useNuxtApp();

  const authStore = useAuthStore($pinia);
  const userStore = useUserStore($pinia);
  const trackingStore = useTrackingStore($pinia);
  const discountStore = useDiscountStore($pinia);
  const checkoutStore = useCheckoutStore($pinia);

  const { login } = authStore;
  const { register } = useRegistration();
  const { locale } = $i18n;
  const { trialDays } = useCheckout();
  const {
    createSubscription,
    createCustomer,
    getSetupIntent,
    extractPaymentMethodFromSetupIntent,
    attachPaymentMethodToCustomer,
  } = useStripeApiClient();
  const { checkoutSuccess } = storeToRefs(checkoutStore);
  const { user, userType } = storeToRefs(userStore);

  const queryParameters = createQueryParameters(to.query);
  const checkoutParameters = unref(useCheckout().checkoutParameters);
  const { isCountryWithTaxRequirements } = useConditions();
  const featureFlags = useFeatureFlags();

  if (queryParameters.has("redirect_status") && !checkoutParameters) {
    Sentry.captureMessage("Checkout parameter object is missing during Stripe redirect", {
      extra: {
        requestQueryParams: queryParameters.getAll(),
        authStore: authStore ?? "undefined",
        userStore: userStore ?? "undefined",
        trackingStore: trackingStore ?? "undefined",
        discountStore: discountStore ?? "undefined",
        checkoutStore: checkoutStore ?? "undefined",
        user: user?.value ?? "undefined",
        userType: userType?.value ?? "undefined",
      },
    });
  }

  if (
    !checkoutParameters ||
    !queryParameters.hasAll(["redirect_status", "setup_intent", "period", "currency", "sku", "amount"])
  ) {
    return;
  }

  const userUUID = checkoutParameters.userUuid;

  const error = useError();

  if (queryParameters.get("redirect_status") === "failed" || error.value) {
    if (error.value) {
      // eslint-disable-next-line no-console
      console.log(`Stripe redirect failed due to error for user ${userUUID}`);

      Sentry.captureMessage("Stripe redirect failed due to error", {
        extra: {
          error: error.value,
          requestQueryParams: queryParameters.getAll(),
          authStore: authStore ?? "undefined",
          userStore: userStore ?? "undefined",
          trackingStore: trackingStore ?? "undefined",
          discountStore: discountStore ?? "undefined",
          checkoutStore: checkoutStore ?? "undefined",
          user: user?.value ?? "undefined",
          userType: userType?.value ?? "undefined",
        },
      });
    }

    return navigateTo("/onboarding/checkout?payment_failed=true");
  }

  // eslint-disable-next-line no-console
  console.log(`[handleCheckoutResult] Get setup intent for user ${userUUID}`);

  const setupIntent = await getSetupIntent(queryParameters.get("setup_intent"));
  const setupIntentMetaData = setupIntent.metadata as SetupIntentMetadata;

  // eslint-disable-next-line no-console
  console.log(`Handling checkout result for user ${userUUID}`, {
    setupIntentMetaData,
    queryParameters: queryParameters.getAll(),
    requestQueryParams: queryParameters.getAll(),
    authStore: authStore ?? "undefined",
    userStore: userStore ?? "undefined",
    trackingStore: trackingStore ?? "undefined",
    discountStore: discountStore ?? "undefined",
    checkoutStore: checkoutStore ?? "undefined",
    user: user?.value ?? "undefined",
    userType: userType?.value ?? "undefined",
  });

  if (!setupIntentMetaData?.country) {
    // eslint-disable-next-line no-console
    console.log(`[handleCheckoutResult] Missing country in setup intent metadata for user ${userUUID}`);

    throw createError({ message: "Invalid setup intent: missing country" });
  }

  // eslint-disable-next-line no-console
  console.log(`[handleCheckoutResult] Extracting payment method for user ${userUUID}`);

  const paymentMethod = extractPaymentMethodFromSetupIntent(setupIntent);

  const autoTax = isCountryWithTaxRequirements && featureFlags.tax_postalcode.isOn().value;

  let customerId: string;

  if (userType.value === "user" && user.value && user.value.stripe_customer_id) {
    customerId = user.value.stripe_customer_id;

    // eslint-disable-next-line no-console
    console.log(`[handleCheckoutResult] Attaching payment method for user ${userUUID}`, {
      customerId,
      paymentMethod,
    });

    await attachPaymentMethodToCustomer({ paymentMethod, customerId });
  } else {
    // eslint-disable-next-line no-console
    console.log(`[handleCheckoutResult] Creating customer for user ${userUUID}`, {
      setupIntentMetaData,
      paymentMethod,
      email: checkoutParameters.email,
      autoTax,
    });

    const { customer_id, status } = await createCustomer({
      setup_intent_metadata: setupIntentMetaData,
      payment_method: paymentMethod,
      email: checkoutParameters.email,
      autoTax,
    });

    if (status === "success") {
      customerId = customer_id;
    } else if (status === "customer_tax_location_invalid") {
      let postalCodeInput;
      if (setupIntent.payment_method && typeof setupIntent.payment_method === "object") {
        postalCodeInput = setupIntent.payment_method.billing_details.address?.postal_code ?? undefined;
      }
      await useBiTrackingClient().trackGeneric({ name: "postal_code.invalid", input: postalCodeInput });
      return navigateTo("/onboarding/checkout?payment_failed=address");
    } else {
      return navigateTo("/onboarding/checkout?payment_failed=true");
    }
  }

  if (userType.value === "guest") {
    // eslint-disable-next-line no-console
    console.log(`[handleCheckoutResult] Register in backend for user ${userUUID}`, {
      email: checkoutParameters.email,
    });

    await register();
    await login(checkoutParameters.email, checkoutParameters.password);
  }

  const transactionId = `${queryParameters.get("period")}_STRIPE_${uuidv4()}`;

  const segment = await discountStore.getActiveSegment();

  const months = getMonthsFromPeriod(queryParameters.get("period"));


  // eslint-disable-next-line no-console
  console.log(`[handleCheckoutResult] Creating subscription for user ${userUUID}`, {
    customerId,
    setupIntentMetaData,
    sku: queryParameters.get("sku"),
    trial_days: months === 12 ? trialDays.value : 0,
    transactionId,
    paymentMethod,
    segment,
    country: setupIntentMetaData.country,
  });

  const subscription = await createSubscription({
    customer_id: customerId,
    sku: queryParameters.get("sku"),
    locale: locale.value,
    trial_days: months === 12 ? trialDays.value : 0,
    transaction_id: transactionId,
    payment_method: paymentMethod,
    payment_method_types: (setupIntent.payment_method_types as StripePaymentMethod[]).filter(
      (method) => ![StripePaymentMethod.BANCONTACT, StripePaymentMethod.IDEAL].includes(method),
    ),
    country: setupIntentMetaData.country,
    setup_intent_metadata: setupIntentMetaData,
    segment,
    autoTax,
  });

  // eslint-disable-next-line no-console
  console.log(`[handleCheckoutResult] Creating subscription done for user ${userUUID}`, {
    subscription,
  });

  checkoutSuccess.value = CheckoutSuccessStatus.PAID;

  if (userType.value === "user" || userType.value === "tokenUser") {
    checkoutSuccess.value = CheckoutSuccessStatus.ACCOUNT_FINALIZED;
  }

  checkoutStore.$persist();

  await useBiTrackingClient().trackPurchase({
    gateway: "stripe",
    period: queryParameters.get("period"),
    currency: queryParameters.get("currency"),
    sku: queryParameters.get("sku"),
    amountCustomerFacingCurrency: parseInt(queryParameters.get("amount")),
  });

  await useAwinTrackingClient().trackPurchase({
    currencyCode: subscription.currency,
    orderReference: transactionId,
    totalAmount: subscription.amount,
    voucherCode: discountStore.code,
  });

  await useIterableTrackingClient().trackPurchase({
    transactionId,
    email: checkoutParameters.email,
    planId: queryParameters.get("sku"),
    totalAmount: subscription.amount,
  });

  await useMetaTrackingClient().trackPurchase({
    transactionId,
    email: checkoutParameters.email,
    planId: queryParameters.get("sku"),
    totalAmount: subscription.amount,
    currency: subscription.currency,
  });

  trackingStore.addPurchaseEvent({
    transactionId,
    gateway: "stripe",
    period: subscription.period,
    currencyCode: subscription.currency,
    sku: queryParameters.get("sku"),
    totalAmount: subscription.amount,
    discountCode: discountStore.code,
    email: checkoutParameters.email,
    trial_days: trialDays.value,
  });

  trackingStore.$persist();

  const redirectRoutes: Record<UserType, string> = {
    guest: "/onboarding/finalize-account",
    user: "/onboarding/success",
    tokenUser: "/onboarding/success",
  };

  return navigateTo(redirectRoutes[userType.value]);
});
